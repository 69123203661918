import React from 'react';

import { Link } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

import image404 from 'Assets/404_image.jpg';

const NotFoundPage = () => (
  <PageWrapper>
    <SEO title="404: Not found" />
    <main className="page__main page__main--simple four-oh-four__main">
      <h1 className="four-oh-four__title">Ahhh... Disaggregated!</h1>
      <p className="four-oh-four__copy">You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/" className="four-oh-four__cta">
        Get back to the homepage.
      </Link>

    </main>
    <img className="four-oh-four__img" src={ image404 } alt="404 error" />

  </PageWrapper>
);

export default NotFoundPage;
